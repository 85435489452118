#nav-bar {
    width: 100vw;
    height: 5em;
    backdrop-filter: blur(10px);
    background-color: rgba(0, 0, 0, 0.4);
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 3;
    font-family: "Lucida Console", "Courier New", monospace;
}
.nav-element {
    color: white;
    padding: 1em 2em; /* Adjust padding as needed */
    margin: 0;
    text-align: center; /* Center text in nav element */
}

.nav-element:active {
    transform: rotate(15deg);
}

.nav-element h1 {
    margin: 0;
    font-weight: normal;
    border-bottom: 2px solid transparent;
    transition: border-bottom-color 0.3s ease; /* Smooth transition for hover effect */
}

.nav-element:hover h1 {
    border-bottom-color: white;
}

body {
    margin: 0;
    padding: 0;
}

