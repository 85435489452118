
#ben-body {
    position: relative;
    width: 100%;
    margin: 0;
    overflow-x: hidden ;
    padding: 5em 0 10em;

}

.ben-section-title {
    color: white; /* Ensure text is visible */
    padding: 1em 0; /* Add some padding */
    background-color: inherit; /* Inherit the background from the parent */
    text-align: center;

}



#ben-page {
    overflow-x: hidden;
    color: white;
    font-family: "Lucida Console", "Courier New", monospace;
    position: relative;
    min-height: 100vh;

}

#ben-top-left-right {
    padding: 5em 15em 0 ;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

#ben-part-of{
    padding: 0 10em;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}
#ben-socials {
    display: flex;
    flex-direction: row;
}
#ben-name {
    font-size: 500%;
    margin: 0;
    transition-duration: 100ms;
}

#ben-name:hover {
    font-size: 550%;
    transition-duration: 100ms;
}

#ben-intro-texts > h2 {
    width: 30em;
}

#ben-avatar {
    animation: bopping-up-and-down 4s infinite ease-in-out;
}

#ben-team-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap; /* Allows wrapping when space is insufficient */
    justify-content: center; /* Centers the wrapped items */
    gap: 2em; /* Adjust gap as needed */
}


.ben-team {

    width: 10em;
    min-width: 10em;
    background-color: #026b78;
    text-align: center;
    padding: 1em;
}


@keyframes bopping-up-and-down{
    0%, 100% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-10%);
    }
}

.ben-project-container {
    background-color: #232324;
}