#rant-body {
    margin: 0;
    padding: 0;
    overflow: hidden;
    min-width: 500px;
}

#rant-background {
    display: block; /* Make sure the canvas covers the entire viewport */
    position: fixed; /* Position the canvas */
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;

}

#rant-content {
    position: absolute;
    width: 100%;
}

#heading {
    color: white;
    font-size: 50px;
    text-align:center;

}