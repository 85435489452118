
#jester-body {
    background: rgb(224,162,184);
    height: 40vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 7em;

}

#jester-header {
    display: flex;
    flex-direction: row;
}

.row-flex {
    display: flex;
    flex-direction: row;
}

#jester-intro {
    text-align: center;
}
#body-brain {
    height: 200px;
}

#jester-education {
    display: flex;
    flex-direction: row;
}