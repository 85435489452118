.github-logo {
    width: 2.5em;
    height: 2.5em;
    padding: 2px;
    border: white solid thin;
    border-radius: 100%;
    transition: padding 0.3s ease;
}

.socials-bar {
    display: flex;
    flex-direction: row;
}
