.angelhome-select-paragraph {
     padding: 0.5em;
    height: 1em;
    font-size: 1.5em;
    text-decoration-line: underline;
    /*border-bottom: 1px solid #4e17ff ;*/
    color: #232324;
    border: 1px solid blueviolet;
}

.angelhome-select-paragraph-container > img {
    height: 2em ;
    width: 2em;

}
.angelhome-select-paragraph-container {
    display: flex;
    flex-direction: column;
    width:100vw;
}

.angelhome-body {
    height: 100%;
    background-color: #a6b4ff;
}

.mom-waterside {
    width: 80vw;
}
.mom-waterside {
    width: 80vw;
}
.mom-waterside > div {
     display: flex;
    flex-direction: column;
 }

@media only screen and (min-width: 768px) {
    .mom-waterside {
        width: 50vw;
    }
}
@media only screen and (min-width: 1000px) {
    .mom-waterside {
        width: 30vw;
    }
}

.mom-waterside-container {
    display: flex;
    justify-content: center;
    padding: 2em;
}
.angelhome-return-button {
    box-shadow: rgba(60, 64, 67, .3) 0 6px 3px 0;
    border-radius: 15px;
    background-color: #be75ee;
    border: 3px solid blueviolet;
    box-shadow: #be75ee;
    height: 5em;
    width: 10em;
}