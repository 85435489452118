#maya-birthday-body {
    background-color: #f4e9ee;
    height: 100%;
    overflow-x: hidden;
    overflow-y: hidden;
}

@import url('https://fonts.cdnfonts.com/css/birthday-2');
@import url('https://fonts.cdnfonts.com/css/lazy-dog');

#happy-birthday-text {
    font-family: 'Birthday', sans-serif;
    font-size: 300%;
    margin: auto;
}

#maya-header-divider {
    display: flex;
    flex-direction: column;
    max-width: fit-content;
    margin-left: auto;
    margin-right: auto;
    padding: 2em;
}

#maya-name-container-div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: blueviolet;
}



#maya-birthday-topbar {
    width: 100vw;

}

#maya-birthday-topbar > img {
    width: 100%;
}


#maya-sun-svg {

    animation: rotate 10s linear infinite; /* 4 seconds, linear speed, infinite loop */
    display: inline-block;
}
@keyframes rotate {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

#maya-moon-svg {
    animation: hover-up-down 2s ease-in-out infinite; /* 2-second duration, easing, infinite loop */
    display: inline-block;
}

@keyframes hover-up-down {
    0% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-15px); /* Moves the element up by 15px */
    }
    100% {
        transform: translateY(0); /* Moves back to the original position */
    }
}

#maya-heart-svg {
    animation: heartbeat 0.7s ease-in-out infinite; /* 2-second duration, easing, infinite loop */
    display: inline-block;
}

@keyframes heartbeat {
    0%, 100% {
        transform: scale(1); /* Normal size */
    }
    50% {
        transform: scale(1.2); /* Enlarges the heart */
    }
}
.maya-stats-svg {
    height: 1.5em;
    width: 1.5em;
}


#maya-birthday-pictures-divider {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative; /* Allows control of positioning */
    z-index: 1; /* Lower than party picture */
    margin-top: 0px;
}

#maya-birthday-pictures-divider div{
    background-color: grey;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80vw;
    height: 40vw;
    border: 7px solid white ;

}

#maya-party-picture {
    width: 100vw;
    z-index: 2;
    position: relative;
    top: -70vw;
}

#maya-birthday-pictures-divider img {
    height: 100%;
}

#maya-birthday-stats {
    padding: 0 2em 0 2em ;
    color: #271125;
    font-family: 'Lazy Dog', sans-serif;

}

.blue-font {
    color: #026b78;
}

.no-vertical-margin {
    margin-top: 0;
    margin-bottom: 0;

}